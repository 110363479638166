"use client";
import { useRef } from "react";
import { Provider } from "react-redux";
import { makeStore, AppStore } from "./index";
import { setUser } from "@/core/reducers/user.reducer";
import { User } from "@prisma/client";
import { UserWithSessions } from "@/actions/user/getUserByEmail";

export default function StoreProvider({
  user,
  children,
}: {
  user: UserWithSessions | null|undefined;
  children: React.ReactNode;
}) {
  const storeRef = useRef<AppStore | null>(null);
  if (!storeRef.current) {
    storeRef.current = makeStore();
    if (user) storeRef.current.dispatch(setUser(user));
    
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
}
